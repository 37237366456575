import React from 'react';
import './Footer.css';

const Footer = () => {
  const year = new Date().getFullYear();
  const shopName = 'Pachila';

  const socialLinks = [
    { id: 'facebook', url: 'https://www.facebook.com/pachila.in/', icon: 'icon-facebook' },
    { id: 'instagram', url: 'https://www.instagram.com/pachila.in/?hl=en', icon: 'icon-instagram' },
    { id: 'youtube', url: 'https://www.youtube.com/@Pachila', icon: 'icon-youtube' },
    { id: 'patreon', url: 'https://www.patreon.com/pachila', icon: 'icon-patreon' },
  ];

  const paymentMethods = ['visa', 'mastercard', 'paypal'];

  const menuLinks = [
    { title: 'About Us', url: '/about-us' },
    { title: 'Contact', url: '/contact' },
  ];

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-column">
          <h2>Menu</h2>
          <ul>
            {menuLinks.map((link, index) => (
              <li key={index}>
                <a href={link.url}>{link.title}</a>
              </li>
            ))}
          </ul>
        </div>

        <div className="footer-column">
          <h2>Pachila</h2>
          <p>Cultivating a Greener Future. Providing eco-friendly solutions to inspire sustainable living. Let's grow together!</p>
          <div className="social-links">
            {socialLinks.map((social, index) => (
              <a key={index} href={social.url} className={social.icon} aria-label={social.id}></a>
            ))}
          </div>
        </div>

        <div className="footer-column">
          <h2>Subscribe to our Newsletter</h2>
          <form className="newsletter-form">
            <input type="email" placeholder="Your email" required />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>

      <div className="footer-address">
        <h3>Our Offices</h3>
        <p>Corporate Office: Live Well, Sukumara Homestay, Coastal Road, Valapad Beach, Kothakulam - 680567, Thrissur, Kerala</p>
        <p>Regional Office: Live Well, 31/27-1, Chakoth Ln, Punkunnam, Thrissur, Kerala - 680002</p>
      </div>

      <div className="footer-bottom">
        <div className="payment-methods">
          <ul>
            {paymentMethods.map((method, index) => (
              <li key={index}>
                <img src={`/icons/${method}.svg`} alt={method} />
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-info">
          <small>&copy; {year}, <a href="/">{shopName}</a></small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
